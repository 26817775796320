
import { getLatestCCBRef, getLatestFile } from "./ccb.js";
import { getLatestCCBRefStorage, uploadLatestCCBFile, uploadOldCCBFile } from "./storage.js";
import { getBytes, deleteObject } from "firebase/storage"



export async function getLatestCCB(id, idType) {

    const storageRef = await getLatestCCBRefStorage(id)

    const ccbRef = await getLatestCCBRef(id, idType)

    if ((storageRef ? storageRef.name : null) !== (ccbRef ? ccbRef.archivo : null ) &&  !ccbRef) {

        const dataBuffer = await getLatestFile(ccbRef)


        await uploadLatestCCBFile(id, dataBuffer, ccbRef).then(
            async () => {
                if (storageRef) {

                    const oldDataBuffer = await getBytes(storageRef)

                    await uploadOldCCBFile(id, oldDataBuffer, storageRef.name).then(async () => await deleteObject(storageRef))

                }
            }
        )

        return dataBuffer

    }else{
        return (await getBytes(storageRef))
    }


}