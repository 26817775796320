import './App.css';
import { Routes, Route } from "react-router-dom"
import { Home } from "./components/Home"
import { Login } from './components/Login';
import { Register } from './components/Register';
import { AuthProvider } from "./Context/authContext"
import { ProtectedRoute } from "./components/ProtectedRoute"
function App() {
  return (
      <AuthProvider>
        <Routes>

          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/login" element={<Login />} />
          {/*<Route path="/register" element={<Register />} />*/}

        </Routes>
      </AuthProvider>
  );
}

export default App;
