// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth" 
import {getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAdD4fg91N_YyiI7Toj-RcW18jqFLWFYP4",
  authDomain: "el-jordan.firebaseapp.com",
  databaseURL: "https://el-jordan.firebaseio.com",
  projectId: "el-jordan",
  storageBucket: "el-jordan.appspot.com",
  messagingSenderId: "422925201542",
  appId: "1:422925201542:web:ce793d571ccba32d78a107",
  measurementId: "G-G87DEMJZF8"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const storage = getStorage(app)
