import {
  AppBar, Box, Button, createTheme, CssBaseline, Container, IconButton, ThemeProvider, Toolbar, Typography, Grid, Paper, TextField,
  Dialog, Slide, Drawer, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import { useAuth } from "../Context/authContext";
import { Logout } from "@mui/icons-material"
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import logoWhite from "../static/logo-white.svg"
import { useState, memo, forwardRef } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { saveAs } from "file-saver";
import { getLatestCCB } from "../firebase/doc";
import { getLatestCCBRefStorage } from "../firebase/storage";
import {getDownloadURL} from "firebase/storage"
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export function Home() {

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false);




  const { logOut } = useAuth()


  const [ptarjeta, setptarjeta] = useState(0)

  const handleLogout = async () => {
    await logOut();
  }

  const saveFile = () => {
    saveAs(
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      "example.pdf"
    );
  };

  const theme = createTheme()

  let COP = Intl.NumberFormat("es-CO")

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };



  // Handle Placa

  const getMixto = (area, espesor) => {

    const mixto = parseFloat(Math.round(area * espesor * 1.4)) / 100

    const diff = mixto - Math.floor(mixto)

    var _mixto = Math.floor(mixto)

    _mixto += diff > 0.25 ? 0.5 : 0 

    _mixto += diff > 0.75 ? 0.5 : 0 


    return _mixto

  }


  const isValidPerfil = (metros) => { return metros <= 9 && metros >= 3 }

  const getPerfiles = (ancho, largo) => {
    const _ancho = Math.ceil(ancho);
    const _largo = Math.ceil(largo);
    var perfiles = []

    if (isValidPerfil(_ancho)) {
      var calc = Math.round(largo / 0.89)
      calc += calc / _largo <= 1 ? 1 : 0
      perfiles.push({
        medida: _ancho,
        cantidad: calc
      })

    }

    if (isValidPerfil(_largo)) {
      var calc2 = Math.round(ancho / 0.89)
      calc2 += calc2 / _ancho <= 1 ? 1 : 0
      perfiles.push({
        medida: _largo,
        cantidad: calc2
      })

    }

    return perfiles
  }

  const getMaterial = (ancho, largo, espesor) => {

    const _ancho = parseFloat(ancho)
    const _largo = parseFloat(largo)
    const _espesor = parseFloat(espesor)

    const area = _ancho * _largo
    const mixto = getMixto(area, _espesor)
    const cemento = Math.round(mixto * 5)
    const bloquelon = Math.round(area * 5)
    const mallas = Math.ceil(area / 14)

    return {
      area: area,
      mixto: mixto,
      cemento: cemento,
      bloquelon: bloquelon,
      mallas: mallas,
      perfiles: getPerfiles(_ancho, _largo)
    }


  }

  const [material, setMaterial] = useState(getMaterial(0, 0, 0))


  const handleSubmit = (e) => {
    e.preventDefault()
    setMaterial(getMaterial(e.target.ancho.value, e.target.largo.value, e.target.espesor.value))
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const Perfiles = () => {
    if (material['perfiles'].length === 0) {
      return <strong>Medidas de perfiles no disponibles</strong>
    }
    return (
      <>
        <strong>Perfiles</strong>

        <ul>
          {material['perfiles'].map(p => { return (<li>{'\u2022'} {p.cantidad} perfiles de {p.medida} metros</li>) })}
        </ul>
      </>

    )
  }

  function saveByteArray(reportName, byte) {
    let blob = new Blob([byte], {type: "application/pdf"});
    let link = document.createElement('a');
    link.target = "_blank";
    link.href = window.URL.createObjectURL(blob);
    let fileName = reportName;
    link.download = fileName;
    link.click();
    setLoading(false);
};

  async function saveFileDoc(){
    setLoading(true);
    // const data =await getDownloadURL(await getLatestCCBRefStorage(28485611))
    // let link = document.createElement('a');
    // link.href = data;
    // link.target = "_blank";
    // let fileName = "CCB.pdf";
    // link.download = fileName;
    //link.click();
    const bufferData = await getLatestCCB(28485611, 1);
    saveByteArray("CCB.pdf", bufferData)
  }




  return (

    <ThemeProvider theme={theme}>
      {loading ? (<div className="loader-container">
      	  <div className="spinner"></div>
        </div>) : <a/>}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="static" sx={{ bgcolor: '#212121', p: 0.5 }}>
          <Toolbar>
            <img src={logoWhite} alt="logo" width="95rem" height="auto" margin="2em" />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
            <IconButton color="inherit" onClick={handleLogout}>
              <Logout />
            </IconButton>

          </Toolbar>
        </AppBar>
      </Box>

      <Box>

        <Container maxWidth="lg" sx={{ mt: 4, sb: 4 }}>
          <Grid container spacing={3}>

            {/* Placa fácil */}
            <Grid item xs={12} md={6} lg={6}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  color: 'white'
                }}
              >
                <Typography component="h2" variant="h6" sx={{ margin: "auto", color: "black" }}><strong>Placa Fácil</strong></Typography>
                <Box component="form" onSubmit={handleSubmit} validated sx={{ mt: 1 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        autoComplete="off"
                        margin="normal"
                        required
                        fullWidth
                        id="ancho"
                        label="Ancho (m)"
                        name="ancho"
                        InputProps={{
                          inputComponent: NumberFormatCustom
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        autoComplete="off"
                        margin="normal"
                        required
                        fullWidth
                        id="largo"
                        label="Largo (m)"
                        name="largo"
                        InputProps={{
                          inputComponent: NumberFormatCustom
                        }}
                      />
                    </Grid>
                  </Grid>


                  <TextField
                    autoComplete="off"
                    margin="normal"
                    required
                    fullWidth
                    id="espesor"
                    label="Espesor Placa (cm)"
                    name="espesor"
                    defaultValue={6}
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      inputComponent: NumberFormatCustom
                    }}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Calcular
                  </Button>
                </Box>


              </Paper>
            </Grid>

            {/* Precios Tarjetas */}
            <Grid item xs={12} md={6} lg={6}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  color: 'white'
                }}
              >
                <Typography component="h2" variant="h6" sx={{ margin: "auto", color: "black" }}><strong>Precios Tarjetas</strong></Typography>
                <TextField
                  id="precio"
                  margin="normal"
                  label="Precio"
                  autoComplete="off"
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Tab' || e.key === 'Enter') {
                      setptarjeta(e.target.value);
                    }
                  }}

                />
                <Typography component="h6" variant="h6" sx={{ color: "black", margin: "auto", pt: 4 }}>Débito 2%: ${COP.format(Math.round(ptarjeta * 1.02))}</Typography>
                <Typography component="h6" variant="h6" sx={{ color: "black", margin: "auto" }}>Crédito 4%: ${COP.format(Math.round(ptarjeta * 1.04))}</Typography>
                <Typography component="h6" variant="h6" sx={{ color: "black", margin: "auto" }}>Crédito 8%: ${COP.format(Math.round(ptarjeta * 1.08))}</Typography>

              </Paper>
            </Grid>

            {/* Descargar Documentos */}
            <Grid item xs={12} md={6} lg={6}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  color: 'white'
                }}
              >
                <Typography component="h2" variant="h6" sx={{ margin: "auto", color: "black" }}><strong>Descargar Documentos</strong></Typography>
                <Button onClick={saveFileDoc} variant="contained">Descargar Certificado CCB</Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            minWidth="md"
          >
            <DialogTitle id="alert-dialog-slide-title" sx={{ fontSize: 28, mx: 2, mt: 2 }}><strong>Material a utilizar para {material['area']} m²</strong></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description"
                sx={{ fontSize: 24, color: "black", mx: 2 }}>
                {material['mixto']} m³ de mixto <br />
                {material['cemento']} sacos de cemento <br />
                {material['bloquelon']} bloquelones <br />
                {material['mallas']} mallas <br />
                <Perfiles />

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" sx={{ mr: 3, fontSize: 18 }}>
                Cerrar
              </Button>
            </DialogActions>


          </Dialog>
        </div>

      </Box>

    </ThemeProvider>
  )
}