import fetch from "node-fetch";

export async function getLatestCCBRef(id, idtype) {

    const responseData = await (await fetch(`https://linea.ccb.org.co/CertificadosElectronicosRApiService/api/certificadosDescarga?numeroDocumento=${id}&tipoDocumento=${idtype}`)).json()

    return responseData[0]
}

export async function getLatestFile(fileRef){

    const responseFile = await fetch(`https://download.ccb.org.co/api/DescargaCertificados?nombreArchivo=${fileRef}`)

    return Buffer.from(await responseFile.arrayBuffer())

}