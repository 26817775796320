import { createContext, useContext, useEffect, useState } from "react"
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut
} from 'firebase/auth'
import { auth } from "../firebase/firebase.js"

export const authContext = createContext()

export const useAuth = () => {

    const context = useContext(authContext)
    if (!context) throw new Error("There is not authProvider")
    return context

}

export function AuthProvider({ children }) {

    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    const signUp = (email, password) => createUserWithEmailAndPassword(auth, email, password)
    const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password)

    const logOut = () => signOut(auth)

    useEffect(() => {
        onAuthStateChanged(auth, currentUser => {
            setUser(currentUser)
            setLoading(false)
        })

    }, [])


    return (
        <authContext.Provider value={{ signUp, signIn, logOut, user, loading }}>
            {children}
        </authContext.Provider>
    )
}