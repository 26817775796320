import { useState } from "react";
import { useAuth } from "../Context/authContext"
import { useNavigate } from "react-router-dom";
import logoWhite from "../static/logo-white.svg"
import {
  Box, Container, createTheme, CssBaseline, ThemeProvider, TextField,
  Button, Typography, Avatar, Paper, AppBar, Toolbar
} from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export function Login() {

  const [user, setUser] = useState({
    email: '',
    password: '',
  })

  const { signIn } = useAuth()
  const navigate = useNavigate()

  const [error, setError] = useState()

  const errorMessage = (message) => {
    switch(message){
      case "auth/user-not-found":
        return "Error: El usuario no existe"
      case "auth/wrong-password":
        return "Error: Contraseña incorrecta"
      case "auth/invalid-email":
        return "Error: Correo inválido"
      default:
        return message
        
    }
  }

  const handleChange = ({ target: { name, value } }) => setUser({ ...user, [name]: value })

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      await signIn(user.email, user.password)
      navigate('/')

    } catch (error) {
      toast.error(errorMessage(error.code), {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
    }

  }


  const theme = createTheme()


  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ bgcolor: '#212121', p: 0.5 }}>
        <Toolbar>
          <img src={logoWhite} alt="logo" width="95rem" height="auto" margin="2em" />


        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              color: 'white'
            }}>
            <Avatar sx={{ m: 2, bgcolor: 'black', margin: "auto" }}>
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ color: "black", margin: "auto", mt: 3 }}>
              Iniciar Sesión
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo Electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                placeholder="email@ejemplo.co"
                onChange={handleChange}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Iniciar Sesión
              </Button>
            </Box>
          </Paper>

        </Box>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Container>
    </ThemeProvider>
  )
}