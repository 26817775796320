import { ref, uploadBytes, listAll } from "firebase/storage"
import { storage } from "./firebase.js";

export async function uploadLatestCCBFile(id, arrayBuffer, fileRef) {
    const storageRef = ref(storage, `${id}/CCB/latest/${fileRef}`)

    const metadata = {
        contentType: 'application/pdf'
    }

    await uploadBytes(storageRef, arrayBuffer, metadata)
}

export async function uploadOldCCBFile(id, arrayBuffer, fileRef) {
    const storageRef = ref(storage, `${id}/CCB/old/${fileRef}`)

    const metadata = {
        contentType: 'application/pdf'
    }

    await uploadBytes(storageRef, arrayBuffer, metadata)
}

export async function getLatestCCBRefStorage(id) {


    const listRef = ref(storage, `${id}/CCB/latest`);
    
    const res  = await listAll(listRef)

    return res.items ? res.items[0] : null

}